import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import InspectionTypesTable from 'collections/InspectionTypesTable';
import { InspectionType } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { InspectionTypeForm } from 'forms/InspectionTypeForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import { toIsoDate } from 'utils/dateHelper';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { AppRoles } from 'entities/enums';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const InspectionTypesView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post } = useAxios();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<InspectionType>();

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const actions: GridColDef<InspectionType>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const handleSubmit: SubmitHandler<InspectionType> = (data) => {
    logger.log('INSPECTIONTYPE SUBMIT', data);
    //post<InspectionType>(`/api/InspectionType`, data).then(() => navigate('/'));
    setShowForm(false);
  };

  useEffect(() => {
    if (id && id > 0) {
      get<InspectionType>(`/api/InspectionType/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Inspection Type: ${entity.name}` : 'New Inspection Type' : <ClientTitle prefix={'Inspection Types for'} showClient />}
      secondary={
        !showForm && (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              navigate('/types/inspection/0');
              setShowForm(true);
            }}
          >
            Add InspectionType
          </Button>
        )
      }
    >
      {showForm ? (
        <InspectionTypeForm
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={() => {
            resetForm();
            navigate('/types/inspection');
          }}
        />
      ) : (
        <InspectionTypesTable actions={actions} />
      )}
    </MainCard>
  );
};
