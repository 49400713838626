import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Building, PulseRelay } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';
import { BuildingVM } from 'entities/viewModels';

const InitialState: PulseRelay = {
  enabled: false,
  contactGroups: [],
  signalTypes: [],
  buildings: [],
  name: '',
  id: 0,
};

type PulseRelayFormProps = {
  entity?: PulseRelay;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<PulseRelay>;
};

export const PulseRelayForm = ({ disabled, entity, onCancel, onSubmit }: PulseRelayFormProps) => {
  const { context } = useAppContext();

  const methods = useForm<PulseRelay>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
      span: 11,
    },
    {
      label: 'Enabled',
      name: 'enabled',
      type: FieldType.CHECKBOX,
      control: methods.control,
      span: 1,
    },
    {
      label: 'Buildings',
      name: 'buildings',
      type: FieldType.MULTISELECT,
      control: methods.control,
      // required: true,
      data: `/api/building/all/${context?.clientId}`,
      span: 6,
      render(value) {
        const building = value as BuildingVM;
        return `${building.name} ${building.site.name ? `[${building.site.name}]` : ''}`;
      },
    },
    {
      label: 'Contact groups',
      name: 'contactGroups',
      type: FieldType.MULTISELECT,
      control: methods.control,
      data: `/api/contactGroup/all/${context?.clientId}`,
      span: 3,
    },
    {
      label: 'Telegram Group ID',
      name: 'telegramChatId',
      type: FieldType.TEXT,
      control: methods.control,
      span: 3,
    },
    {
      label: 'Signal types',
      name: 'signalTypes',
      type: FieldType.MULTISELECT,
      control: methods.control,
      // required: true,
      data: `/api/signalType/all/${context?.systemId}`,
      span: 12,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: PulseRelay) => onSubmit(d)} />;
};
