import { CircularProgress, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { renderProgress } from 'components/dataGrid/renderers/ProgressBar';
import { Inspection } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, PlayArrow, Pause } from '@mui/icons-material';
import { InspectionState } from 'entities/enums/InspectionState';
import useInspection from 'hooks/useInspection';

type InspectionsTableProps = {
  reload?: Symbol;
  limited?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  pageSizeOptions?: number[];
};

function RenderActionCell(params: GridRenderCellParams<Inspection, any, any, GridTreeNodeWithRender>): ReactNode {
  const { handleState } = useInspection();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleEdit = (id: number) => {
    navigate(`/inspections/${id}`);
  };

  useEffect(() => setLoading(false), [params.row.state]);

  return (
    <Stack direction='row' spacing={0.1} height={'100%'} alignItems='center'>
      <IconButton size='small' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <Edit />
      </IconButton>
      <IconButton
        size='small'
        color='inherit'
        onClick={() => {
          setLoading(true);
          handleState(params.row.id).then(() => setLoading(false));
        }}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : params.row.state === InspectionState.Paused ? (
          <PlayArrow />
        ) : params.row.state === InspectionState.InProgress ? (
          <Pause />
        ) : (
          <></>
        )}
      </IconButton>
    </Stack>
  );
}

export default function InspectionsTable({ reload, hideFooter, hideHeader }: InspectionsTableProps) {
  const apiRef = useGridApiRef();
  const { context } = useAppContext();
  const { inspection } = useInspection();
  const [dataUrl, setDataUrl] = useState<string>();

  const [refresh, setRefresh] = useState<Symbol>();

  const columns: GridColDef<Inspection>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    {
      field: 'state',
      headerName: 'Status',
      filterable: false,
      width: 140,
      renderCell(params) {
        return <>{InspectionState[parseInt(params.value)].toUpperCase()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Inspection',
      filterable: false,
      width: 300,
    },
    {
      field: 'startDate',
      headerName: 'Start date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleDateString()}</>;
      },
    },
    {
      field: 'progress',
      headerName: 'Progress',
      filterable: false,
      width: 200,
      renderCell: renderProgress,
    },
  ];

  useEffect(() => {
    setRefresh(Symbol());
  }, [inspection, inspection?.state]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}`);
  }, [context?.systemId]);

  useEffect(() => {
    setDataUrl(`/api/inspection/query/${context?.systemId}`);
  }, []);

  return (
    <DataGrid
      api={apiRef}
      refresh={refresh}
      key={'inspectionsTable'}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
      orderBy={'startDate desc'}
      columnDefinitions={columns}
      dataUrl={dataUrl}
    />
  );
}
