import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import EventItemsTable from 'collections/EventItemsTable';
import { EventItem } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { EventItemForm } from 'forms/EventItemForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import { ImportExportOutlined } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { AppRoles } from 'entities/enums';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const EventsView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post } = useAxios();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<EventItem>();

  const resetForm = () => {
    setId(undefined);
    setShowForm(false);
    setEntity(undefined);
  };

  const actions: GridColDef<EventItem>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin]);
      },
    },
  ];

  const handleSubmit: SubmitHandler<EventItem> = (data) => {
    logger.log('EventItem SUBMIT', data);
    // TODO post data
    setShowForm(false);
  };

  useEffect(() => {
    if (id && id > 0) {
      get<EventItem>(`/api/event/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Event: ${entity.name}` : 'New Event Log' : <ClientTitle prefix={'Event logs for'} showClient />}
      secondary={
        showForm ? (
          <Button
            variant='outlined'
            startIcon={<ImportExportOutlined />}
            onClick={() => {
              // TODO export
            }}
          >
            Export
          </Button>
        ) : (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              navigate('/events/0');
              setShowForm(true);
            }}
          >
            Add Event Log
          </Button>
        )
      }
    >
      {showForm ? (
        <EventItemForm
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={() => {
            resetForm();
            navigate('/events');
          }}
        />
      ) : (
        <EventItemsTable actions={actions} />
      )}
    </MainCard>
  );
};
