import { ReactNode, createContext, useEffect, useReducer } from 'react';
import useAxios from 'hooks/useAxios';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { ActionMap } from 'types/actionMap';
import { Signal } from 'entities';
import useAppContext from 'hooks/useAppContext';
import { SignalStateVM, SignalVM } from 'entities/viewModels';
import { SimulationModel } from 'components/shared/SignalPlayer';

type SignalProviderProps = {
  children: ReactNode;
};

export enum SignalActionTypes {
  SignalUpdate = 'SIGNAL_UPDATE',
  SetSignalStates = 'SET_SIGNALSTATES',
  SignalStateUpdate = 'SIGNAL_STATE_UPDATE',
  ResetSignalStates = 'RESET_SIGNALSTATES',
  SimulateSignals = 'SIMULATE_SIGNALS',
}

type SignalPayload = {
  [SignalActionTypes.SignalUpdate]: Signal;
  [SignalActionTypes.SignalStateUpdate]: SignalStateVM;
  [SignalActionTypes.ResetSignalStates]: number;
  [SignalActionTypes.SimulateSignals]: SimulationModel | undefined;
};

export type SignalContextState = {
  signal?: SignalVM;
  signalState?: SignalStateVM;
  simulationModel?: SimulationModel;
  // states?: SignalStateVM[];
};

export interface SignalContextType extends SignalContextState {
  // resetSignalStates: (systemId: number) => Promise<void>;
  setSimulateSignals: (model?: SimulationModel) => void;
}

type SignalActions = ActionMap<SignalPayload>[keyof ActionMap<SignalPayload>];

const initialSignalState: SignalContextState = {};

export const SignalContext = createContext<SignalContextType | null>(null);

const signalReducer = (state: SignalContextState, action: SignalActions): SignalContextState => {
  // TODO make hash map
  switch (action.type) {
    case SignalActionTypes.SignalUpdate: {
      return { ...state, signal: action.payload };
    }
    case SignalActionTypes.SignalStateUpdate: {
      // const _states: SignalStateVM[] = addOrUpdateArray(state.states ?? [], action.payload);
      return { ...state, signalState: action.payload };
    }
    // case SignalActionTypes.SetSignalStates: {
    //   return { ...state, states: action.payload };
    // }
    case SignalActionTypes.SimulateSignals: {
      return { ...state, simulationModel: action.payload };
    }
    // case SignalActionTypes.ResetSignalStates: {
    //   return { ...state, states: [] };
    // }
    default: {
      return { ...state };
    }
  }
};

export const SignalProvider = ({ children }: SignalProviderProps) => {
  const { context } = useAppContext();
  const { get, post, postWithFormData } = useAxios();
  const [state, dispatch] = useReducer(signalReducer, initialSignalState);
  const { signalHandler, signalStateHandler } = BroadcastHelper();

  // const setSignalStates = async (systemId: number) => {
  //   var states = await get<SignalStateVM[]>(`/api/signalstate/${systemId}/active`);
  //   dispatch({ type: SignalActionTypes.SetSignalStates, payload: states });
  // };

  const setSimulateSignals = (model?: SimulationModel) => {
    dispatch({ type: SignalActionTypes.SimulateSignals, payload: model });
  };

  // const resetSignalStates = async (systemId: number) => {
  //   const success = await post(`/api/signalstate/${systemId}/reset`);
  //   if (success) await setSignalStates(systemId);
  // };

  useEffect(() => {
    signalHandler((signal: Signal) => {
      dispatch({ type: SignalActionTypes.SignalUpdate, payload: signal });
    });
    signalStateHandler((signalState: SignalStateVM) => {
      dispatch({ type: SignalActionTypes.SignalStateUpdate, payload: signalState });
    });
  }, []);

  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       if (context?.buildingId) await setSignalStates(context?.buildingId);
  //     } catch (err) {
  //       logger.logError(`Failed to init SignalContext:`, err);
  //     }
  //   };

  //   init();
  // }, []);

  return (
    <SignalContext.Provider
      value={{
        ...state,
        // resetSignalStates,
        setSimulateSignals,
      }}
    >
      {children}
    </SignalContext.Provider>
  );
};
