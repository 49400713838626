import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelItemProps {
  index: number;
  label: string;
  children?: React.ReactNode;
}

type TabPanelProps = {
  selected?: number;
  items?: TabPanelItemProps[];
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabPanel({ items, selected }: TabPanelProps) {
  const [value, setValue] = React.useState(selected ?? 0);

  const TabPanelItem = (props: TabPanelItemProps) => {
    const { children, index, ...other } = props;

    return (
      <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    items && (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label='tabs-panel'>
            {items.map((i, k) => (
              <Tab key={i.index} label={i.label} {...a11yProps(i.index)} />
            ))}
          </Tabs>
        </Box>
        {items.map((item) => (
          <TabPanelItem key={item.index} {...item} />
        ))}
      </Box>
    )
  );
}
