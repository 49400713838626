import { CheckCircle, HelpOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Beacon } from 'entities';
import { SignalParseType } from 'entities/enums';
import { BeaconConfigVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';

interface BeaconsTableActions extends DataGridActionProps<Beacon> {}

export default function BeaconsTable({ actions }: BeaconsTableActions) {
  const { context } = useAppContext();
  const columns: GridColDef<Beacon>[] = [
    {
      field: 'pulses',
      headerName: 'Pulses',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.name === 'BEACON-006' ? (
          <Box height={'100%'} display='flex' alignContent={'center'} alignItems={'center'} gap={1}>
            <CheckCircle color='success' />
            <CheckCircle color='success' />
            <CheckCircle color='success' />
            <CheckCircle color='success' />
            <CheckCircle color='success' />
          </Box>
        ) : (
          <Box height={'100%'} display='flex' alignContent={'center'} alignItems={'center'} gap={1}>
            <HelpOutline />
            <HelpOutline />
            <HelpOutline />
            <HelpOutline />
            <HelpOutline />
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 200,
    },
    {
      field: 'configs',
      headerName: 'System(s)',
      filterable: false,
      width: 500,
      renderCell(params) {
        var configs = params.row.configs as BeaconConfigVM[];
        return configs
          ?.map((c) => ` ${c.system?.building?.shortName} - ${c.system?.name} (SYSID: ${c.system?.id}, PARSER: ${c.parser ? SignalParseType[c.parser] : ''} )`)
          .join('; ');
      },
    },
    {
      field: 'antiFloodEnabled',
      headerName: 'Anti Flood Signals',
      filterable: false,
      width: 300,
      renderCell(params) {
        return params.value ? `Enabled, ${params.row.antiFloodCount} signals within ${(params.row.antiFloodInterval ?? 0) / 1000} seconds` : 'Not enabled';
      },
    },
  ];

  return (
    <DataGrid
      key={'beaconsTable'}
      dataUrl={`/api/beacon/query/${context?.clientId}`}
      hideHeader={false}
      orderBy={'name asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
