import Loader from 'components/shared/Loader';
import { Inspection, InspectionResult } from 'entities';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap } from '../types/actionMap';
import { InspectionState } from 'entities/enums/InspectionState';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { InspectionResultVM } from 'entities/viewModels';
import { MarkerType } from 'components/maps/base/MapMarker';
import { updateArray } from 'utils/array';

type InspectionProviderProps = {
  children: ReactNode;
};

interface InspectionContextState {
  reload?: Symbol;
  type: MarkerType;
  inspection?: Inspection;
  drawerData?: InspectionResultVM;
}

enum InspectionActionTypes {
  Reload,
  SetMarkerType,
  SetInspection,
  // SetInspectionResult,
  SetDrawerData,
}

interface InspectionContextType extends InspectionContextState {
  handleState: (id?: number) => Promise<void>;
  getInspection: (id?: number) => Promise<void>;
  setMarkerType: (show: MarkerType) => Promise<void>;
  setDrawerData: (data?: InspectionResultVM) => Promise<void>;
  handleInspectionSubmit: (data: Inspection) => Promise<void>;
  handleInspectionResultSubmit: (data: InspectionResult) => Promise<void>;
}

type InspectionPayload = {
  [InspectionActionTypes.Reload]: Symbol | undefined;
  [InspectionActionTypes.SetMarkerType]: MarkerType;
  [InspectionActionTypes.SetInspection]: Inspection | undefined;
  // [InspectionActionTypes.SetInspectionResult]: InspectionResult | undefined;
  [InspectionActionTypes.SetDrawerData]: InspectionResultVM | undefined;
};

type InspectionActions = ActionMap<InspectionPayload>[keyof ActionMap<InspectionPayload>];

const inspectionReducer = (state: InspectionContextState, action: InspectionActions): InspectionContextState => {
  switch (action.type) {
    case InspectionActionTypes.Reload:
      return { ...state, reload: action.payload };
    case InspectionActionTypes.SetMarkerType:
      return { ...state, type: action.payload };
    case InspectionActionTypes.SetInspection:
      return { ...state, inspection: action.payload };
    // case InspectionActionTypes.SetInspectionResult:
    //   return state.inspection && action.payload && state.inspection.id === action.payload.inspectionID
    //     ? { ...state, inspection: { ...state.inspection, results: updateArray(state.inspection?.results ?? [], action.payload) } }
    //     : { ...state };
    case InspectionActionTypes.SetDrawerData:
      return { ...state, drawerData: action.payload };
    default:
      return { ...state };
  }
};

export const InspectionContext = createContext<InspectionContextType | null>(null);

export const InspectionProvider = ({ children }: InspectionProviderProps) => {
  const { get, patch } = useAxios();
  const { error, success } = useNotification();
  const { inspectionStateHandler, inspectionResultHandler } = BroadcastHelper();

  const [state, dispatch] = useReducer(inspectionReducer, { type: 'Uninspected' });

  const getInspection = async (id?: number) => {
    if (id && id > 0) {
      const inspection = await get<Inspection>(`/api/inspection/${id}`);
      dispatch({ type: InspectionActionTypes.SetInspection, payload: inspection });
    } else dispatch({ type: InspectionActionTypes.SetInspection, payload: undefined });
  };

  const handleState = async (id?: number) => {
    if (!id) return;
    const item = state.inspection?.id === id ? state.inspection : await get<Inspection>(`/api/inspection/${id}`);
    if (item && !item.endDate) {
      const alternateState = item.state === InspectionState.Paused ? InspectionState.InProgress : InspectionState.Paused;
      await patch(`/api/inspection/${item.id}/state/${alternateState}`);
    }
  };

  const setMarkerType = (type: MarkerType) => {
    dispatch({ type: InspectionActionTypes.SetMarkerType, payload: type });
    return Promise.resolve();
  };

  const setDrawerData = (item?: InspectionResultVM) => {
    dispatch({ type: InspectionActionTypes.SetDrawerData, payload: item });
    return Promise.resolve();
  };

  const handleInspectionSubmit = async (data: Inspection) => {
    //TODO implement formatter
    data.type = undefined;
    // console.log('SUBMITTING', data);
    await patch(`/api/inspection/${data.id}`, data).then((ok) => {
      setDrawerData(undefined);
      if (ok) success(`Successfully updated ${data.name}`);
      else error(`Failed to update ${data.name}`);
      // get<Inspection>(`/api/inspection/${id}`).then(setInspection);
    });
  };

  const handleInspectionResultSubmit = async (data: InspectionResultVM) => {
    const address = data.device?.address;
    data.device = undefined;
    data.signals = [];
    // console.log('SUBMITTING', data);
    // TODO make reviver based in NON VM
    // const entity = JSON.parse(JSON.stringify(data), (key, value) => {
    //   const valueType = typeof value;
    //   const _value = Array.isArray(value) && valueType !== 'string' ? [] : valueType !== 'object' ? value : undefined;
    //   console.log(`[${key}]:`, _value);
    //   return key ? _value : value;
    // });
    // console.log('DATA', data);
    // console.log('ENTITY', entity);
    await patch(`/api/inspectionresult/${data.id}`, data).then(async (ok) => {
      setDrawerData(undefined);
      if (ok) success(`Successfully updated ${address ?? ''}`.trim());
      else error(`Failed to update ${address ?? ''}`.trim());
    });
  };

  useEffect(() => {
    inspectionStateHandler(async (inspectonState: { id: number; state: InspectionState }) => {
      // TODO refresh only when its active or in list of inspections
      // if (inspectonState.id === state.inspection?.id) {
      await getInspection(inspectonState.id);
      // }
    });
    inspectionResultHandler(async (inspectionResult: InspectionResult) => {
      await getInspection(inspectionResult.inspectionID);
      dispatch({ type: InspectionActionTypes.Reload, payload: Symbol() });
    });
  }, []);

  if (!state) {
    return <Loader />;
  }

  return (
    <InspectionContext.Provider value={{ ...state, handleState, getInspection, handleInspectionSubmit, handleInspectionResultSubmit, setDrawerData, setMarkerType }}>
      {children}
    </InspectionContext.Provider>
  );
};
