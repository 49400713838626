import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './header';
import Sidebar from './sidebar/Sidebar';
import HorizontalBar from '../../components/shared/HorizontalBar';
import MainContentStyled from './Dashboard.styled';
import Loader from 'components/shared/Loader';
import Breadcrumbs from 'components/extended/Breadcrumbs';
import useConfig from 'hooks/useConfig';
import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';
import { MenuOrientation } from 'types/theme';
import { useVersion } from 'hooks/useVersion';
import { Typography } from '@mui/material';
import useAppContext from 'hooks/useAppContext';
import { ClientTitle } from 'components/shared/ClientTitle';
import { Html5QrcodeCameraScanConfig, Html5Qrcode as QrScanner } from 'html5-qrcode';
import useAxios from 'hooks/useAxios';
import logger from 'utils/logger';
import useNotification from 'hooks/useNotification';
import { Device } from 'entities';

// ==============================|| MAIN LAYOUT ||============================== //

const DashboardLayout = () => {
  useVersion(); // check version and reload
  const theme = useTheme();
  const { get } = useAxios();
  const { success, error } = useNotification();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));

  const { context, setBarcode, setContext } = useAppContext();

  // const [qrCode, setQrCode] = useState<string>();
  const [showScanner, setShowScanner] = useState(false);
  const toggleQrScanner = () => setShowScanner(!showScanner);
  const [scanner, setScanner] = useState<QrScanner | undefined>();
  const config: Html5QrcodeCameraScanConfig = { fps: 10 };

  const { borderRadius, container, miniDrawer, menuOrientation } = useConfig();
  const { menuMaster, menuMasterLoading } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  useEffect(() => {
    handlerDrawerOpen(!miniDrawer);
  }, [miniDrawer]);

  useEffect(() => {
    downMD && handlerDrawerOpen(false);
  }, [downMD]);

  function onScanSuccess(decodedText: any, decodedResult: any) {
    logger.log(`SCANNED: ${decodedText}`);
    setBarcode(decodedText);
    toggleQrScanner();
  }

  function onScanFailure(error: any) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    error(`Code scan error = ${error}`);
  }

  useEffect(() => {
    // For testing barcode
    // if (showScanner) {
    //   setBarcode('AA005038');
    //   toggleQrScanner();
    //   return;
    // }
    if (showScanner) {
      const qrScanner = new QrScanner('reader');
      qrScanner?.start({ facingMode: 'environment' }, config, onScanSuccess, onScanFailure).catch((e) => {
        logger.logError('Failed to start camera.', error);
        error('Failed to start camera.');
      });
      setScanner(qrScanner);
    } else {
      if (scanner?.isScanning)
        scanner?.stop().catch((e) => {
          logger.logError('Failed to clear QrScanner. ', e);
        });
    }
  }, [showScanner]);

  // useEffect(() => {
  //   if (qrCode) {
  //     success(`Scanned: ${qrCode}`);
  //     get<number>(`/api/device/barcode/${qrCode}`)
  //       .then((id) => {
  //         console.log('SCANNED DEVICE ID', id);
  //         get<Device>(`/api/device/${id}`)
  //           .then((d) => {
  //             console.log('SCANNED DEVICE', d);
  //             if (d.systemID !== context?.systemId) setContext(d.systemID).then(() => setBarcode(qrCode));
  //             else setBarcode(qrCode);
  //           })
  //           .catch((e) => logger.logError(`FAILED '/api/device/${id}' with:`, e));
  //       })
  //       .catch((e) => logger.logError(`FAILED '/api/device/barcode/${qrCode}' with:`, e))
  //       .finally(() => {
  //         toggleQrScanner();
  //         setQrCode(undefined);
  //       });
  //   }
  // }, [qrCode]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;

  // horizontal menu-list bar : drawer
  const menu = useMemo(() => (isHorizontal ? <HorizontalBar /> : <Sidebar />), [isHorizontal]);

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* header */}
      <AppBar enableColorOnDark position='fixed' color='inherit' elevation={0} sx={{ bgcolor: 'background.default' }}>
        <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
          <Header handleClick={toggleQrScanner} />
        </Toolbar>
        {downMD && context && (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ mt: -2 }}>
            <Typography variant='subtitle1'>
              <ClientTitle showSiteCode showBuilding showSystem />
            </Typography>
          </Box>
        )}
      </AppBar>
      {!showScanner && (
        <>
          {/* menu / drawer */}
          {menu}

          {/* main content */}
          <MainContentStyled {...{ borderRadius, menuOrientation, open: drawerOpen /*, theme*/ }}>
            <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
              {/* breadcrumb */}
              {/* <Breadcrumbs /> */}
              <Outlet />
            </Container>
          </MainContentStyled>
        </>
      )}
      {showScanner && (
        <Box width={'100%'} height={'93vh'} sx={{ backgroundColor: 'black', marginTop: '7vh' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <div id='reader' style={{ width: '100%', height: '100%', maxWidth: '600px', maxHeight: '600px' }}></div>
        </Box>
      )}
    </Box>
  );
};

export default DashboardLayout;
