import { RouterProvider, useLocation } from 'react-router-dom';

// routing
import { router } from 'AppRoutes';

// project imports
import Locales from 'components/shared/Locales';
import NavigationScroll from 'components/shared/NavigationScroll';

import ThemeCustomization from 'themes';

// style + assets
import 'assets/scss/style.scss';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import Loader from 'components/shared/Loader';

// providers
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'contexts/ThemeContext';
import { AppProvider } from 'contexts/AppContext';
import { MapProvider } from 'contexts/MapContext';
import { InspectionProvider } from 'contexts/InspectionContext';
import { SignalProvider } from 'contexts/SignalContext';

// ==============================|| APP ||============================== //

const App = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider>
        <ThemeCustomization>
          <Locales>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
              <AppProvider>
                <MapProvider>
                  <SignalProvider>
                    <InspectionProvider>
                      <NavigationScroll>
                        <RouterProvider router={router} fallbackElement={<Loader />} />
                      </NavigationScroll>
                    </InspectionProvider>
                  </SignalProvider>
                </MapProvider>
              </AppProvider>
            </CookiesProvider>
          </Locales>
        </ThemeCustomization>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
