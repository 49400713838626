import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import useNotification from './useNotification';
import { AppConfig, InitialAppConfig } from 'entities/base/AppConfig';
import useAxios from './useAxios';

export const useVersion = () => {
  const { get } = useAxios();
  const location = useLocation();
  const { success } = useNotification();
  const [config, setConfig] = useLocalStorage<AppConfig>('bs-config', InitialAppConfig);

  useEffect(() => {
    get<string>(`/api/versioning`).then(async (version) => {
      if (window.history.state?.bsUpgrade) {
        window.history.replaceState({}, '');
        success(`Beacon Suite upgraded to V${version}`);
        // localStorage.removeItem('bs-version'); //TODO remove
      } else if (config?.version !== version) {
        setConfig({ ...config, version: version });
        window.history.pushState({ bsUpgrade: true }, '');
        window.location.reload();
      }
    });
  }, [location]);
};
