import React from 'react';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import { Autocomplete, Box, Button, Checkbox, Grid2 as Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useNotification from 'hooks/useNotification';
import useAxios from 'hooks/useAxios';
import { ContactGroup, Pulse } from 'entities';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PulseList from 'collections/PulseList';
import { PulseForm } from 'forms/PulseForm';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import useAppContext from 'hooks/useAppContext';
import { ClientTitle } from 'components/shared/ClientTitle';

const PulseView = () => {
  const { get, post } = useAxios();
  const { contact } = useAppContext();
  const { error, success } = useNotification();
  const [preview, setPreview] = useState(false);

  // const [subject, setSubject] = useState('');
  // const [message, setMessage] = useState('');
  // const [receipts, setReceipts] = useState<ContactGroup[]>();

  // const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

  const [entity, setEntity] = useState<Pulse>();

  const isAuthorized = inRole(contact?.role, [AppRoles.Admin]);

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  const resetForm = () => {
    setPreview(false);
    setEntity(undefined);
  };

  const onSendClick = () => {
    if (!entity) return;
    entity.date = new Date().toISOString();
    entity.contactGroups?.forEach((c, i, array) => {
      array[i] = { ...c, contacts: [], pulseRelays: [] };
    });
    //entity.date = new Date().toISOString();
    // console.log('SENDPULSE', entity);
    // if (!receipts || receipts.length === 0) return;
    // setPreview(false);
    // var pulse: Pulse = {
    //   message: message,
    //   name: subject,
    //   id: 0,
    //   date: new Date().toISOString(),
    //   contactGroups: receipts,
    //   //contactGroupIds: receipts?.map((g) => g.id) ?? [],
    // };
    post<Pulse>(`/api/pulse/send/`, entity).then((ok) => {
      if (ok) {
        resetForm();
        success(`Send pulse '${entity?.name}'`);
      } else error(`Failed to send: '${entity?.name}'`);
      // setEntity(undefined)
      //   setSubject('');
      //   setMessage('');
      //   setReceipts(undefined);
    });
  };

  // const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
  //   if (event?.key !== 'Enter') {
  //     return;
  //   }
  //   handleOnSend();
  // };

  // const renderContactGroupInfo = (group: ContactGroup): { label: string; disabled: boolean } => ({ label: group.name, disabled: false });

  // useEffect(() => {
  //   get<ContactGroup[]>(`/api/contactGroup/query`).then(setContactGroups);
  // }, []);

  useEffect(() => {
    if (entity) setPreview(true);
  }, [entity]);

  if (!isAuthorized)
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Grid container spacing={gridSpacing}>
          <Grid size={{ xs: 12 }}>
            <MainCard title={'Pulse History'}>
              <PulseList />
            </MainCard>
          </Grid>
        </Grid>
      </Box>
    );

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={gridSpacing}>
        <Grid size={{ xs: 12, md: 8 }}>
          <MainCard title={<ClientTitle prefix='Pulse for' showClient />}>
            <Box component='div' sx={{ width: '100%', height: '100%' }}>
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  {!preview && isAuthorized && (
                    <PulseForm disabled={!inRole(contact?.role, [AppRoles.Admin])} onCancel={() => resetForm()} onSubmit={(e) => setEntity(e)} entity={entity} />
                  )}
                  {preview && isAuthorized && (
                    <>
                      <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <TextField autoComplete='off' variant='outlined' label={'Subject'} value={`BeaconSuite: ${entity?.name}`} disabled />
                        <Autocomplete
                          multiple
                          fullWidth
                          disabled={true}
                          value={entity?.contactGroups ?? []}
                          options={entity?.contactGroups ?? []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          getOptionKey={(option) => option.id}
                          renderInput={(params) => <TextField autoComplete='off' {...params} label='Contact Group(s)' />}
                        />
                        <TextField autoComplete='off' variant='outlined' label='Message' multiline rows={5} value={entity?.message} disabled />
                      </Box>
                      <Button variant='contained' onClick={() => resetForm()} sx={{ px: 0.75, mt: 2, mr: 1 }}>
                        Cancel
                      </Button>
                      <Button variant='contained' color={'secondary'} onClick={onSendClick} sx={{ px: 0.75, mt: 2 }}>
                        Send
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </MainCard>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <MainCard title={'Pulse History'}>
            <PulseList />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PulseView;
