import { LocationOn } from '@mui/icons-material';
import { IconButton, Link, Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender, GridColDef } from '@mui/x-data-grid';
import { InspectionResultAction } from 'components/actions/InspectionResultAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { MarkerType } from 'components/maps/base/MapMarker';
import { InspectionResult } from 'entities';
import { InspectionResultVM } from 'entities/viewModels';
import { ReactNode, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useInspection from 'hooks/useInspection';
import useAppContext from 'hooks/useAppContext';

type InspectionResultsTableProps = {
  type: MarkerType;
  refresh?: Symbol;
  loading: boolean;
  onClick: (item: InspectionResult) => Promise<void>;
  onLocationClick: (address?: string) => void;
};

export const InspectionResultsTable = ({ type, refresh, loading, onClick, onLocationClick }: InspectionResultsTableProps) => {
  const { context } = useAppContext();
  const { inspection } = useInspection();
  const [dataUrl, setDataUrl] = useState<string>();

  const RenderActionCell = (params: GridRenderCellParams<InspectionResult, any, any, GridTreeNodeWithRender>): ReactNode => {
    return <InspectionResultAction item={params.row} onClick={onClick} />;
  };

  const RenderMapActionCell = (params: GridRenderCellParams<InspectionResultVM, any, any, GridTreeNodeWithRender>): ReactNode => {
    if (params.row.device?.address === null) return <></>;

    return (
      <Stack direction='row' height={'100%'} alignItems='center'>
        <IconButton size='small' title='Show on map' color='inherit' onClick={() => onLocationClick(params.row.device?.address)}>
          <LocationOn />
        </IconButton>
      </Stack>
    );
  };

  const getInspectionTypeDataColumns = (): GridColDef<InspectionResultVM>[] =>
    !context?.hasSignals
      ? []
      : [
          {
            field: 'signals',
            headerName: 'Signals',
            width: 400,
            filterable: false,
            renderCell(params) {
              const signals =
                params.row.signals
                  ?.map((s) => s.type?.name ?? '')
                  .filter((t) => t.length > 0)
                  .join(', ') ?? '';
              return <div title={signals}>{signals}</div>;
            },
          },
        ];

  const columns: GridColDef<InspectionResultVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderMapActionCell,
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params);
      },
    },
    {
      field: 'device.Description',
      headerName: 'Description',
      width: 500,
      filterable: false,
      renderCell(params) {
        const description = params.row.device?.description ?? '';
        return <div title={description}>{description}</div>;
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      filterable: false,
      renderCell(params) {
        return params.value ? new Date(params.value).toLocaleString() : '';
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      filterable: false,
      renderCell(params) {
        return <div title={params.row.device?.type?.name ?? ''}>{params.row.device?.type?.name ?? ''}</div>;
      },
    },
    {
      field: 'device.Address',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row.device ? (
          <Link component={RouterLink} to={`/devices/${params.row.deviceID}`}>
            {params.row.device?.address ?? ''}
          </Link>
        ) : (
          <></>
        );
      },
    },
    ...getInspectionTypeDataColumns(),
  ];

  useEffect(() => {
    if (inspection) setDataUrl(`/api/inspectionresult/query/${inspection.id}/${type}`);
  }, [type, inspection]);

  return <DataGrid id={'inspectionResultsTable'} refresh={refresh} isLoading={loading} hideHeader={false} dataUrl={dataUrl} orderBy='date desc' columnDefinitions={columns} />;
};
