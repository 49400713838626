// material-ui
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// project imports
import SubCard from 'components/cards/SubCard';
import AnimateButton from 'components/extended/AnimateButton';
import { gridSpacing } from 'config';

// assets
import useAppContext from 'hooks/useAppContext';

const Profile = () => {
  const { contact } = useAppContext();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={6} md={4}>
        <SubCard title='Profile Picture' contentSX={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Avatar alt='User 1' sx={{ width: 100, height: 100, margin: '0 auto' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' align='center'>
                Upload/Change Your Profile Image
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AnimateButton>
                <Button variant='contained' size='small'>
                  Upload Avatar
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
      <Grid item sm={6} md={8}>
        <SubCard title='Edit Account Details'>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField id='outlined-basic1' fullWidth label='Name' defaultValue={contact?.name} helperText='Helper text' />
            </Grid>
            <Grid item xs={12}>
              <TextField id='outlined-basic6' fullWidth label='Email address' defaultValue='m.faber@beacon-suite.com' />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField id='outlined-basic4' fullWidth label='Company' defaultValue='Beacon Suite LTD.' />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField id='outlined-basic5' fullWidth label='Country' defaultValue='USA' />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id='outlined-basic7'
                fullWidth
                label='Phone number'
                defaultValue='4578-420-410 '
                helperText={<>{`By filling in your phone number you agree to the terms & conditions, you can opt-out in the Notification section`}</>}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField id='outlined-basic8' fullWidth label='Birthday' defaultValue='02/06/2001' />
            </Grid>
            <Grid item xs={12}>
              <Stack direction='row'>
                <AnimateButton>
                  <Button variant='contained'>Change Details</Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default Profile;
