import { Box, Button, FormControl, Grid2 as Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import logger from '../utils/logger';
import { LoadingButton } from '@mui/lab';
import useNotification from '../hooks/useNotification';
import useAxios from 'hooks/useAxios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const ReportsDevicesTab = () => {
  const { get } = useAxios();
  const { info } = useNotification();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>();
  const [type, setType] = useState<number | string>();

  const handleExport = () => {
    setLoading(true);
    const link = document.createElement('a');
    link.target = '_blank';
    const query = `/api/reports/export/${type}`;
    // get(query, { responseType: 'blob' })
    //   .then((res) => {
    //     if (res.data.size === 0) {
    //       info(`There is no ${type == 1 ? 'untested' : type == 2 ? 'not inspected' : 'tested'} data available`);
    //       return;
    //     }
    //     const contentDisposition = res.headers['content-disposition'] as string;
    //     link.download = contentDisposition.split(';')[1].split('=')[1];
    //     link.href = URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
    //     link.click();
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     logger.logError(`FAILED ${query}`, err);
    //     setLoading(false);
    //   });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component='div' style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'flex-start' }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <DatePicker label={'From'} sx={{ width: '100%' }} disabled={true} defaultValue={startDate} onChange={(d) => setStartDate(d)} />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <DatePicker label={'Till'} sx={{ width: '100%' }} disabled={true} defaultValue={endDate} onChange={(d) => setEndDate(d)} />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <FormControl fullWidth>
              <InputLabel id='type-label'>Test type</InputLabel>
              <Select labelId='type-label' id='type-select' value={type ?? ''} label='Test type' onChange={(e) => setType(e.target.value)} disabled={loading}>
                <MenuItem value={0}>Tested</MenuItem>
                <MenuItem value={1}>Untested</MenuItem>
                <MenuItem value={2}>Not Inspected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <LoadingButton loading={loading} variant='outlined' onClick={handleExport} disabled={type === undefined || type.toString().length === 0}>
              <ImportExportIcon />
              Export
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};
