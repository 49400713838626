export const InitialAppConfig: AppConfig = {
  autoNavigate: true,
};

export type AppConfig = {
  version?: string;
  systemId?: number;
  buildingId?: number;
  viewStates?: string;
  mapStates?: string;
  autoNavigate?: boolean;
};
