import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Device } from 'entities';
import { DeviceVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';

interface DevicesTableActions extends DataGridActionProps<DeviceVM> {}

export default function DevicesTable({ actions }: DevicesTableActions) {
  const { context } = useAppContext();
  const columns: GridColDef<DeviceVM>[] = [
    {
      field: 'name',
      headerName: 'Label',
      filterable: false,
      width: 350,
    },
    {
      field: 'address',
      headerName: 'Address',
      filterable: false,
      width: 150,
    },
    {
      field: 'description',
      headerName: 'Description',
      filterable: false,
      width: 350,
    },
    {
      field: 'type',
      headerName: 'Type',
      filterable: false,
      width: 250,
      renderCell(params) {
        return params.row.type?.name ?? '';
      },
    },
    {
      field: 'system.building',
      headerName: 'Loction',
      filterable: false,
      width: 250,
      renderCell(params) {
        const site = params.row.system?.building?.site?.code;
        const location = [params.row.area, params.row.section, params.row.floor, params.row.room].filter((v) => (v?.length ?? 0) > 0).join(' / ');
        const value = params.row.system?.building?.name ? `${site ? `${site} -` : ''} ${params.row.system?.building?.name} ${location ? ` - ${location}` : ''}`.trim() : '';
        return <div title={value}>{value}</div>;
      },
    },
  ];

  return (
    <DataGrid
      key={'devicesTable'}
      dataUrl={`/api/device/query/${context?.systemId}`}
      hideHeader={false}
      orderBy={'name asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
