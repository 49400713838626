import { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Divider, ListItemText, IconButton, ButtonGroup, Button, FormControlLabel, Box, FormLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Delete, PlayArrow, PlayArrowOutlined, Save } from '@mui/icons-material';
import useMaps from 'hooks/useMaps';
import useAppContext from 'hooks/useAppContext';
import { MapItem } from 'entities';
import { exportJson, exportFile } from 'utils/exportHelpers';
import { LayersMap } from 'contexts/MapContext';

type MapLayerGeneratorProps = {
  width?: number;
};

export const MapGenerator = ({ width }: MapLayerGeneratorProps) => {
  const { context } = useAppContext();
  const { layerItems, map, layers, setLayers, setMapItems } = useMaps();

  const [mapId, setMapId] = useState(0);
  const [value, setValue] = useState<string>('');

  const handleResetClick = () => {
    resetLayers();
    setValue('');
  };

  const handleSaveClick = () => {
    const timestamp = new Date().getTime();
    if (layerItems) exportJson(`${map?.id}_MAPITEMS_${timestamp}`, layerItems as unknown as MapItem[]);

    const devicesSVG = document.getElementById('bs-devices-layer') as unknown as SVGElement;
    if (devicesSVG) exportFile(devicesSVG.outerHTML, `${map?.id}_DEVICES_${timestamp}.svg`);
  };

  const resetLayers = () => {
    const _layers: LayersMap = {};
    Object.keys(layers).forEach((k) => (_layers[k] = k.indexOf('gen') === -1 ? layers[k] : false));
    setLayers(_layers);
  };

  useEffect(() => {
    if (!value) {
      resetLayers();
      setMapItems(undefined);
    } else setLayers({ ...layers, [value]: true });
  }, [value]);

  useEffect(() => {
    if (map && map.id !== mapId) {
      setValue('');
      setMapId(map.id);
    }
  }, [map, layers]);

  return (
    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
      <FormLabel>{'Generate map items:'}</FormLabel>
      <ButtonGroup disabled disableElevation sx={{ ml: 1 }} variant='contained'>
        <Button disabled={value !== ''} onClick={() => setValue('generate')}>
          <PlayArrow />
        </Button>
        <Button disabled={!value} onClick={handleResetClick}>
          <Delete />
        </Button>
        <Button disabled={!value || !layerItems} onClick={handleSaveClick}>
          <Save />
        </Button>
      </ButtonGroup>
    </Box>
  );
};
