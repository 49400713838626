import { AppRoles } from 'entities/enums/AppRoles';

export const trimTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const distinct = (data: number[]) => data.filter((value, index, array) => array.indexOf(value) === index && value !== undefined);

export const isNumber = (value: string | number) => isNaN(Number(value)) === false;

export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): [K, V[]][] {
  //Map<K, Array<V>> {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map.entries()); // map;
}

export const leadingZeros = (value: any, count: number) => value.toString().padStart(count, '0');

export const inRole = (role?: string, roles?: AppRoles[]) => {
  return !role ? false : roles && roles.length > 0 ? roles?.find((r) => role === r) !== undefined : true;
};

export const getNumberText = (value: number) => {
  switch(value){
    case 1: return 'Once';
    case 2: return 'Twice';
    default: return `${value} times`
  }  
}