import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid2 as Grid, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import SignalsTable from 'collections/SignalsTable';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import { Toggler } from 'components/shared/Toggler';
import { Beacon } from 'entities';
import { SignalParseType, SystemState } from 'entities/enums';
import { SignalVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import useConfirm from 'hooks/useConfirm';
import useSignals from 'hooks/useSignals';
import { useEffect, useState } from 'react';
import logger from 'utils/logger';

export const SignalerView = () => {
  const { get, post } = useAxios();
  const { context, contact } = useAppContext();
  const { confirm, ConfirmDialog } = useConfirm();

  const [loading, setLoading] = useState(false);
  const [buildingId, setBuildingId] = useState<number>();

  const [beacon, setBeacon] = useState<string>();
  const [beaconParser, setBeaconParser] = useState<SignalParseType>();

  const [value, setValue] = useState('');
  const [signals, setSignals] = useState<string[]>([]);
  const [foundSignalMessage, setFoundSignalMessage] = useState('');

  const customColumns: GridColDef<SignalVM>[] = [
    {
      field: 'subject',
      headerName: 'Subject',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'text',
      headerName: 'Raw Signal',
      width: 500,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return (
          <div
            title={`Double click to add: \r\n${params.value}`}
            onDoubleClick={() => {
              const _value = value + params.value + '\r\n';
              setValue(_value);
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  const handleCancel = () => {
    setValue('');
    setSignals([]);
    setLoading(false);
  };

  const handleSubmit = () => {
    if (!beacon) return;
    confirm(`Are you sure you want to post ${signals.length} signals`).then(async (ok) => {
      if (!ok) return;
      setLoading(true);
      try {
        for (let index = 0; index < signals.length; index++) {
          const signal = signals[index];
          await post<{ name: string; subject: string; text: string }>(`/api/signal/store/${context?.systemId}/true`, { name: beacon, subject: 'UNPARSED FROM BS', text: signal });
        }
      } finally {
        setLoading(false);
      }
    });
  };

  const getSignals = (): string[] => {
    const _signals: string[] = [];
    const values = value?.split(/\r?\n/) ?? [];

    if (beaconParser === SignalParseType.EST) {
      let signal = '';
      for (let index = 0; index < values.length; index++) {
        const value = values[index];

        if (value.trim().length === 0 || index === value.length - 1) {
          if (signal.length > 0) _signals.push(signal);
          signal = '';
        } else signal += value + '\r\n';
      }
    } else _signals.push(...values.filter((s: string) => (s?.trim().length ?? 0) !== 0));

    if (_signals.length > 0) {
      const message = `Found ${_signals.length} signals to import`;
      setFoundSignalMessage(message);
      logger.log(message, _signals);
    } else setFoundSignalMessage('');

    return _signals;
  };

  const handleOnChange = (e: any) => {
    setValue(e.target.value ?? '');
  };

  useEffect(() => {
    setSignals(getSignals());
  }, [value]);

  useEffect(() => {
    if (context?.buildingId) {
      setBuildingId(context?.buildingId);
    }

    get<Beacon>(`/api/beacon/system/${context?.systemId}`).then((b) => {
      if (b) {
        setBeacon(b.name);
        setBeaconParser(b.configs?.find((c) => c.systemId === context?.systemId)?.parser);
      }
    });
  }, [context]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <MainCard title={<ClientTitle prefix='Signaler for' showBuildingShortName showSystem />}>
        <Box display={'flex'} flexDirection={'column'}>
          <TextField value={value} fullWidth label='Paste signals here' multiline rows={10} onChange={handleOnChange} />
          <Box component='div' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 2, mb: 2, mt: 2 }}>
            <Typography>{foundSignalMessage}</Typography>
            <Button type='button' variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton disabled={signals?.length === 0 || (!beacon && !beaconParser)} loading={loading} type='submit' variant='contained' onClick={handleSubmit}>
              SITM
            </LoadingButton>
          </Box>
        </Box>
      </MainCard>
      <MainCard title={<ClientTitle prefix={`Signals for`} showBuildingShortName showSystem />}>
        <ConfirmDialog />
        <SignalsTable customColumns={customColumns} showActions={false} type='All' />
      </MainCard>
    </Box>
  );
};
