import { Box, IconButton, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import logger from '../utils/logger';
import useNotification from '../hooks/useNotification';
import { DataGrid } from '../components/dataGrid/DataGrid';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { CloudDownload } from '@mui/icons-material';
import useAppContext from 'hooks/useAppContext';

function RenderActionCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>): ReactNode {
  const handleExport = (id: number) => {
    logger.log('handleExport(%s)', id);
  };

  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton disabled size='small' aria-label='edit' color='inherit' onClick={() => handleExport(params.row.id)}>
        <CloudDownload />
      </IconButton>
    </Stack>
  );
}

export const ReportsTestsTab = () => {
  const { info } = useNotification();
  const { context } = useAppContext();

  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    {
      field: 'date',
      headerName: 'Start Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        var date = new Date(params.value);
        return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'end',
      headerName: 'End Date',
      width: 250,
      filterable: true,
      renderCell(params) {
        if (params.value) {
          var date = new Date(params.value);
          return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
        }

        return '-';
      },
    },
    {
      field: 'signals',
      headerName: 'Signals',
      width: 200, //150,
    },
    {
      field: 'devices',
      headerName: 'Devices',
      width: 200, //150,
    },
    // {
    //   field: 'tests',
    //   headerName: 'Tests',
    //   width: 150,
    // },
  ];

  return (
    <>
      <Box component='div' style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'flex-start' }}>
        <DataGrid
          columnDefinitions={columns}
          dataUrl={`/api/reports/signalTestReport/${context?.systemId}`}
          orderBy='date desc'
          // dataUrl={`/api/reports/signalTestReport/`}
        />
      </Box>
    </>
  );
};
