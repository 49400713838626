import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid2';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import TabPanel from 'components/shared/TabPanel';
import { ReportsDevicesTab } from './ReportsDevicesTab';
import { ReportsTestsTab } from './ReportsTestsTab';

export const ReportsView = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard title={<ClientTitle prefix={'Reports for'} showBuildingShortName showSystem />}>
          <TabPanel
            items={[
              { index: 0, label: 'Test reports', children: <ReportsTestsTab /> },
              { index: 1, label: 'Device reports', children: <ReportsDevicesTab /> },
            ]}
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};
