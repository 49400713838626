import { ImageOverlay, LayerGroup, SVGOverlay, WMSTileLayer, useMap } from 'react-leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import useAppContext from 'hooks/useAppContext';
import logger from 'utils/logger';
import { LatLngBounds, LatLngBoundsExpression, SVGOverlay as LeafletSVGOverlay, TileLayer } from 'leaflet';
import useAxios from 'hooks/useAxios';
import { MAPCENTER, MapType, MAXZOOM, MINZOOM } from './MapContainer';
import { useWindowSize } from 'hooks/useWindowSize';
import useMaps from 'hooks/useMaps';
import useConfirm from 'hooks/useConfirm';
import { MapMarkerLayer } from './base/MapMarkerLayer';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { showLayer, renderLayerItems } from 'helpers/LayerHelper';
import { Map as BSMap } from 'entities';
import useNotification from 'hooks/useNotification';
import { MapItemVM } from 'entities/viewModels';

type MapLayersProps = {
  mapType: MapType;
};

export function MapLayers({ mapType }: MapLayersProps) {
  const lmap = useMap();
  const { get } = useAxios();
  const { width } = useWindowSize();
  const { contact, context } = useAppContext();
  const { map, layerItems, layers, mapItem, addMarker, setLayerItems, moveToItem } = useMaps();

  const { confirm, ConfirmDialog } = useConfirm();
  const { info, error, success } = useNotification();

  const tileOverlayRef = useRef<TileLayer.WMS>(null);

  const [zoom, setZoom] = useState(MINZOOM);

  const [mapId, setMapId] = useState<number>();

  const overlayHandlers = useMemo(() => ({}), []);
  const [overlay, setOverlay] = useState<string>();
  const overlayRef = useRef<LeafletSVGOverlay>(null);

  const [overlayBounds, setOverlayBounds] = useState<LatLngBounds>();
  const [discalimerBounds, setDisclaimerBounds] = useState<LatLngBounds>();
  const getBounds = (map: BSMap): LatLngBounds | undefined => (map ? new LatLngBounds([map.south, map.west], [map.north, map.east]) : undefined);

  const devicesOverlayRef = useRef<LeafletSVGOverlay>(null);
  //const [devicesOverlayBounds, setDevicesOverlayBounds] = useState<LatLngBoundsExpression>(new LatLngBounds([-256, 0], [0, 256]));

  const resetMapView = () => {
    setZoom(MINZOOM);
    lmap.setView(MAPCENTER, MINZOOM, { animate: false });
  };

  useEffect(() => {
    if (overlayRef.current !== null && map && mapType === 'edit' && layers.generate) {
      resetMapView();
      renderLayerItems(overlayRef, lmap, map).then((items) => {
        const _items = items as unknown as MapItemVM[];
        info(`Generated ${_items.length} items from layers`);
        setLayerItems(_items);
      });
    } else setLayerItems([]);
  }, [layers]);

  // useEffect(() => {
  //   if (zonesOverlayRef.current) {
  //     // TODO set visibility based on MapZone.UID
  //     //console.log(zoneOverlayRef.current?.getElement());
  //   }
  // }, [zonesOverlayRef, zonesOverlay]);

  useEffect(() => {
    if (mapItem) {
      lmap.flyTo([mapItem.lat, mapItem.lng], 7);
      lmap.invalidateSize(true);
      moveToItem(undefined);
    }
  }, [mapItem]);

  useEffect(() => {
    if (overlayRef && layers) showLayer(overlayRef, layers, mapType);
    // if (showLayer(overlayRef, layers, mapType)) {
    //   // remove available map id if already generated in layers.svg
    //   // setDevicesLayerV1([...(devicesLayerV1.filter((id) => id !== mapId) ?? [])]);
    // }
  }, [Object.values(layers)]);

  useEffect(() => {
    if (map?.id !== mapId && !mapItem) {
      resetMapView();
      setOverlay(undefined);
    }
  }, [map?.id]);

  useEffect(() => {
    if (map) setOverlayBounds(getBounds(map));
  }, [map]);

  useEffect(() => {
    if (!map) {
      setOverlay(undefined);
      return;
    }

    logger.log('INIT MapLayer.map', map);

    if (!discalimerBounds) setDisclaimerBounds(getBounds(map));

    if (tileOverlayRef.current && map) {
      get<string>(`/api/map/layers/${context?.systemId}/${map?.id}/layers`).then((svg) => {
        if (svg && svg.length > 0) setOverlay(svg);
      });
      setMapId(map.id);
      // setLayer(map.layer);
      tileOverlayRef.current.setUrl(map.url);
      lmap.invalidateSize(true);
      lmap.zoomControl.addTo(lmap);
      //setMapInfo({ ...mapInfo, mapBounds: lmap.getBounds() });
    }

    // only have this mouse event for adding markers
    if (map && mapType === 'edit' && inRole(contact?.role, [AppRoles.Admin])) {
      lmap.addEventListener('mouseup', (e) => {
        addMarker({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          mapID: map?.id,
          name: '',
          id: 0,
        });
      });
    }

    lmap.addEventListener('zoomend', () => setZoom(lmap.getZoom()));

    return () => {
      addMarker(undefined);
      tileOverlayRef.current?.setUrl('');
      lmap.removeEventListener('mouseup');
      lmap.removeEventListener('zoomend');
    };
  }, [map]);

  return (
    <>
      <ConfirmDialog />
      {/* {discalimerBounds && <ImageOverlay interactive={false} url='disclaimer.png' bounds={discalimerBounds} opacity={0.2} />} */}
      <WMSTileLayer
        tms={true}
        format='image/png'
        ref={tileOverlayRef}
        // crossOrigin={true}
        url={map?.url ?? ''}
        minZoom={MINZOOM - (mapType === 'edit' ? 2 : 0)}
        maxZoom={MAXZOOM}
        // maxNativeZoom={MAXZOOM}
        accessToken={localStorage.getItem('bs-token') ?? undefined}
        //errorTileUrl='/empty.png'
      />
      <LayerGroup>
        {map && mapType !== 'edit' && <MapMarkerLayer map={map} mapType={mapType} zoom={zoom} />}

        {map && mapType === 'edit' && <MapMarkerLayer map={map} mapType={mapType} zoom={zoom} layerItems={layerItems} />}

        {overlay && overlayBounds && (
          <SVGOverlay className={mapType === 'edit' ? 'svg-border-red' : ''} ref={overlayRef} key={`overlays_${map?.id}`} bounds={overlayBounds} eventHandlers={overlayHandlers}>
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' id={`${`overlays_${map?.id}`}`} dangerouslySetInnerHTML={{ __html: overlay }} />
          </SVGOverlay>
        )}

        {/* {mapType === 'edit' && devicesLayerV1.includes(map?.id ?? 0) && (
          <SVGOverlay ref={devicesOverlayRef} key={`devices_overlay_${map?.id}`} bounds={new LatLngBounds([-256, 0], [0, 256])} eventHandlers={overlayHandlers}>
            <BSDevicesLayerTemplate />
          </SVGOverlay>
        )} */}
      </LayerGroup>
      {/* {overlayItems?.map((m) => <MapMarker zoom={zoom} mapType={mapType} size={iconSize} item={m} key={m.id} />)} */}
    </>
  );
}
