import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { System } from 'entities';
import { IntervalType } from 'entities/enums/IntervalType';
import { InspectionTypeVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';
import { ReactNode } from 'react';
import { getNumberText } from 'utils/extensions';

interface InspectionTypesTableActions extends DataGridActionProps<InspectionTypeVM> {}

export default function InspectionTypesTable({ actions }: InspectionTypesTableActions) {
  const { context } = useAppContext();

  const columns: GridColDef<InspectionTypeVM>[] = [
    { field: 'name', headerName: 'Name', width: 400, filterable: false },
    {
      field: 'systemType',
      headerName: 'System Type',
      width: 400,
      filterable: false,
      renderCell(params) {
        return params.value?.name ?? '';
      },
    },
    {
      field: 'interval',
      headerName: 'Interval',
      width: 200,
      filterable: false,
      renderCell(params): ReactNode {
        return (
          <>{params.row.intervalValue && params.row.intervalType ? `${getNumberText(params.row.intervalValue)} a ${IntervalType[params.row.intervalType].toLowerCase()}` : 'N/A'}</>
        );
      },
    },
  ];

  return <DataGrid id={'inspectionTypesTable'} dataUrl={`/api/inspectionType/query/${context?.clientId}`} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
