import React from 'react';

// material-ui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project imports
import SubCard from 'components/cards/SubCard';
import AnimateButton from 'components/extended/AnimateButton';
import { gridSpacing } from 'config';

const Notifications = () => {
  const [state1, setState1] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
    checkedD: false,
    checkedE: true,
    checkedF: false,
  });
  const handleChangeState1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState1({ ...state1, [event.target.name]: event.target.checked });
  };
  const [state3, setState3] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const handleChangeState3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState3({ ...state3, [event.target.name]: event.target.checked });
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} sm={6} md={8}>
        <Grid container spacing={gridSpacing}>
          <Grid item>
            <SubCard title='Pulse Subscription Center'>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>I would like to receive:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={state1.checkedA} onChange={handleChangeState1} name='checkedA' color='primary' />}
                          label='Pulse messages by text'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={state1.checkedB} onChange={handleChangeState1} name='checkedB' color='primary' />}
                          label='Pulse Messages by email'
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} md={4}>
        <SubCard title='Opt out'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox onChange={handleChangeState3} name='checkedA' color='primary' />} label='Unsubscribe me from Pulse Messages' />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Stack direction='row'>
                <AnimateButton>
                  <Button variant='contained' size='small'>
                    Update my preferences
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default Notifications;
