import { ReactElement, useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import useLocalStorage from 'hooks/useLocalStorage';
import { AppConfig, InitialAppConfig } from 'entities/base/AppConfig';

type ToggleItem = {
  name: string;
  disabled?: boolean;
  icon?: ReactElement;
};

type TogglerProps = {
  id?: string;
  value: string;
  values: ToggleItem[];
  disabled?: boolean;
  hidden?: boolean; // TODO not working
  onChange: (value: string) => void;
};

export const Toggler = ({ id, value, values, disabled, hidden, onChange }: TogglerProps) => {
  const [selected, setSelected] = useState(value);
  const [map, setMap] = useState<Map<string, string>>();
  const [config, setConfig] = useLocalStorage<AppConfig>('bs-config', InitialAppConfig);

  const render = (newSelection: string) => {
    onChange(newSelection);
  };

  const handleShowToggle = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue && newValue !== selected) {
      render(newValue);
      if (id && map) {
        map.set(id, newValue);
        setConfig({ ...config, viewStates: JSON.stringify(Array.from(map.entries())) });
      }
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (id) {
      const map = config.viewStates ? new Map<string, string>(JSON.parse(config.viewStates)) : new Map<string, string>();
      setMap(map);
      render(map.get(id) ?? value);
    }
  }, []);

  return (
    <ToggleButtonGroup size='small' value={selected} exclusive onChange={handleShowToggle} disabled={disabled || hidden} hidden={hidden}>
      {values.map((v) => (
        <ToggleButton key={v.name} sx={v.icon ? {} : { width: 80 }} value={v.name} disabled={v.disabled} aria-label='aligned'>
          {v.icon ?? v.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
