import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { InspectionType } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: InspectionType = {
  name: '',
  id: 0,
};

type InspectionTypeFormProps = {
  entity?: InspectionType;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<InspectionType>;
};

export const InspectionTypeForm = ({ disabled, entity, onCancel, onSubmit }: InspectionTypeFormProps) => {
  const methods = useForm<InspectionType>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'System',
      name: 'System(s)',
      type: FieldType.MULTISELECT,
      data: '/api/system/query',
      control: methods.control,
      span: 6,
    },
    {
      label: 'Recurrence (optional)',
      name: 'intervalValue',
      select: 'intervalType',
      type: FieldType.INTERVAL,
      control: methods.control,
      span: 6,
    },
    {
      label: 'Questions',
      name: 'questions',
      type: FieldType.DATAGRID,
      dataUrl: '/api/question/inspection/',
      dataIdField: 'InspectionTypeID',
      control: methods.control,
      fullWidth: true,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: InspectionType) => onSubmit(d)} />;
};
