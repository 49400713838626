import { Divider, Grid2 as Grid, Stack, Switch, Typography } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender, GridColDef } from '@mui/x-data-grid';
import MainCard from 'components/cards/MainCard';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { ClientTitle } from 'components/shared/ClientTitle';
import { SignalType } from 'entities';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import { ReactNode, useState } from 'react';
import logger from 'utils/logger';

function RenderActionCell(params: GridRenderCellParams<SignalType, any, any, GridTreeNodeWithRender>): ReactNode {
  const { patch } = useAxios();
  const [showMap, setShowMap] = useState(params.row.mapVisible ?? false);
  const [showInspection, setShowInspection] = useState(params.row.inspectionVisible ?? false);

  const handleMapShown = (id: number, show: boolean) => {
    logger.log('handleSignalTypeVisibility(%s, %s, %s)', id, show, showInspection);
    patch(`/api/signalType/Visibility?id=${id}&mapVisible=${show}&inspectionVisible=${showInspection}`).then(() => setShowMap(show));
  };

  const handleInspectionShown = (id: number, show: boolean) => {
    logger.log('handleSignalTypeVisibility(%s, %s, %s)', id, showMap, show);
    patch(`/api/signalType/Visibility?id=${id}&mapVisible=${showMap}&inspectionVisible=${show}`).then(() => setShowInspection(show));
  };

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <Switch checked={showMap} onChange={(e) => handleMapShown(params.row.id, e.target.checked)} />
      <Typography>{showMap ? 'Shown' : 'Hidden'} on Map</Typography>
      <Divider />
      <Switch checked={showInspection} onChange={(e) => handleInspectionShown(params.row.id, e.target.checked)} />
      <Typography>{showInspection ? 'Shown' : 'Hiden'} in Inspection</Typography>
    </Stack>
  );
}

export const SignalTypesView = () => {
  const { context } = useAppContext();

  // const resetForm = () => {
  //   setId(undefined);
  //   setShowForm(false);
  //   setEntity(undefined);
  // };

  const columns: GridColDef[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 300 },
    {
      field: 'actions',
      headerName: '',
      width: 500,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
  ];

  return (
    <Grid size={{ xs: 12, md: 4 }}>
      <MainCard title={<ClientTitle prefix={'Signal Types for'} showBuildingShortName showSystem />}>
        <DataGrid id={'signalTypesTable'} dataUrl={`/api/signalType/query/${context?.systemId}`} orderBy='name asc' columnDefinitions={columns} />
      </MainCard>
    </Grid>
  );
};
