import { ReactNode, createContext, useEffect, useReducer } from 'react';
import { Account, Building, Client, Contact, Map, Site, System } from '../entities';
import Loader from 'components/shared/Loader';
import useAxios from 'hooks/useAxios';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { ActionMap } from 'types/actionMap';
import logger from 'utils/logger';
import { AppConfig, InitialAppConfig } from 'entities/base/AppConfig';
import { SystemState } from 'entities/enums';
import useLocalStorage from 'hooks/useLocalStorage';
import { ContactVM } from 'entities/viewModels';
import { updateArray } from 'utils/array';

type AppProviderProps = {
  children: ReactNode;
};

enum AppActionTypes {
  // auth actions
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Reset = 'RESET',

  //Broadcast
  SystemUpdate = 'SYSTEM_UPDATE',

  SetBarcode = 'SET_BARCODE',
  SetContext = 'SET_CONTEXT',

  // global
  Error = 'ERROR',
  Loading = 'LOADING',
}

type AppPayload = {
  [AppActionTypes.Login]: AppContextState;
  [AppActionTypes.Logout]: string | undefined;
  [AppActionTypes.Reset]: boolean;

  [AppActionTypes.SystemUpdate]: { id: number; state: SystemState };

  [AppActionTypes.SetBarcode]: string | undefined;
  [AppActionTypes.SetContext]: AppContextState;
};

interface AuthResponse {
  userId: string;
  authorizationToken: string;
  refreshToken: string;
  user: Account;
}

type SystemContextState = {
  clientId?: number;
  siteId?: number;
  buildingId?: number;
  systemId?: number;

  timeZone?: string;
  clientName?: string;
  siteCode?: string;
  siteName?: string;
  buildingName?: string;
  buildingShortName?: string;
  systemName?: string;
  systemState?: SystemState;

  hasSignals: boolean;

  maps?: Map[];
};

export type AppContextState = {
  barcode?: string;
  feedback?: string; // feedback to user at login screen
  isLoggedIn: boolean;
  isInitialized?: boolean;
  // userId?: string;

  context?: SystemContextState;
  contact?: ContactVM;

  clients?: Client[];
  sites?: Site[];
  buildings?: Building[];
};

export interface AppContextType extends AppContextState {
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: () => Promise<void>;

  setBarcode: (barcode?: string) => Promise<void>;
  setContext: (systemId: number) => Promise<void>;
}

type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

const initialAppState: AppContextState = {
  barcode: undefined,
  feedback: undefined,
  isLoggedIn: false,
  isInitialized: false,
  // userId: undefined,

  // this is the system in operation
  context: undefined,

  contact: undefined,
  clients: undefined,
  sites: undefined,
  buildings: undefined,
};

export const AppContext = createContext<AppContextType | null>(null);

const appReducer = (state: AppContextState, action: AppActions): AppContextState => {
  // TODO make hash map
  switch (action.type) {
    case AppActionTypes.Login:
      return { ...action.payload, isLoggedIn: true, isInitialized: true, feedback: undefined };
    case AppActionTypes.Logout:
      return { ...initialAppState, isInitialized: true, feedback: action.payload };
    case AppActionTypes.SystemUpdate: {
      const systemState = action.payload;
      const system = state.contact?.systems?.find((s) => s.id === systemState.id);
      return state.context && system
        ? {
            ...state,
            contact: { ...state.contact!, systems: updateArray(state.contact?.systems ?? [], { ...system, state: systemState.state }) },
            context: state.context.systemId == systemState.id ? { ...state.context, systemState: systemState.state } : state.context,
          }
        : { ...state };
    }
    case AppActionTypes.SetBarcode:
      return { ...state, barcode: action.payload };
    case AppActionTypes.SetContext:
      return { ...(action.payload ?? state) };
    default: {
      return { ...state };
    }
  }
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { get, postWithFormData } = useAxios();
  const [state, dispatch] = useReducer(appReducer, initialAppState);
  const { systemStateHandler } = BroadcastHelper();
  const [config, setConfig] = useLocalStorage<AppConfig>('bs-config', InitialAppConfig);

  const getState = async (systemId: number, state: AppContextState): Promise<AppContextState> => {
    logger.log('PRESTATE', state);

    if (systemId) {
      setConfig({ ...config, systemId: systemId });

      const system = state.contact?.systems?.find((s) => s.id === systemId);

      const building = state.buildings?.find((b) => b.id === system?.buildingID);
      const site = state.sites?.find((s) => s.id === building?.siteID);
      const client = state.clients?.find((s) => s.id === site?.clientID);

      system?.maps?.forEach(async (map) => {
        map.url = `${process.env.REACT_APP_API_BASEURL?.replace(/\/$/, '')}/api/map/tile/${map.id}/{z}/{x}/{y}.png`;
      });

      // load all maps overlays..
      // await system?.maps?.forEach(async (map) => {
      //   map.url = `${process.env.REACT_APP_API_BASEURL?.replace(/\/$/, '')}/api/map/tile/${map.id}/{z}/{x}/{y}.png`;
      //   await map.overlays?.forEach(async (overlay) => (overlay.svg = await get(`/api/map/overlay/${map.id}/${overlay.fileName}`)));
      // });
      if (system && building && site && client)
        state = {
          ...state,
          context: {
            hasSignals: system?.hasSignals,
            systemState: system?.state,
            systemName: system?.name,
            buildingName: building?.name,
            buildingShortName: building?.shortName,
            buildingId: building?.id,
            clientId: client?.id,
            clientName: client?.name,
            maps: system?.maps,
            siteId: site?.id,
            siteCode: site?.code,
            siteName: site?.name,
            systemId: systemId,
            timeZone: site?.timeZone,
          },
        };
    }

    logger.log('POSTSTATE', state);
    return state;
  };

  const initApp = async (/*userId: string*/) => {
    try {
      logger.log('INIT APP', config);
      const account = await get<Account>('/api/account/me');

      const systemId = account.contact?.systems?.find((s) => s.id === config?.systemId)?.id ?? account.contact?.systems?.at(0)?.id;

      // console.log('LOGIN SYS:', systemId);

      if (account && systemId) {
        const state = await getState(systemId, {
          ...account,
          // userId: userId,
          isLoggedIn: true,
        });
        dispatch({
          type: AppActionTypes.Login,
          payload: state,
        });
      } else {
        // logout if the user doesnt have any permissions
        await logout('No systems found, please contact support..');
      }
    } catch (e) {
      logger.logError('FAILED to initialize', e);
      await logout('Account not found, please contact support..');
    }
  };

  const login = async (email: string, password: string) => {
    const { authorizationToken, userId } = await postWithFormData<{ userName: string; password: string }, AuthResponse>('/api/account/login', {
      userName: email,
      password: password,
    });
    localStorage.setItem('bs-token', authorizationToken);
    if (authorizationToken) await initApp(); //userId);
  };

  const logout = (feedback?: string) => {
    localStorage.removeItem('bs-token');
    dispatch({ type: AppActionTypes.Logout, payload: feedback });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = async () => {};

  const setBarcode = async (barcode?: string) => {
    dispatch({ type: AppActionTypes.SetBarcode, payload: barcode });
  };

  const setContext = async (systemId: number) => {
    dispatch({ type: AppActionTypes.SetContext, payload: await getState(systemId, { ...state, context: undefined }) });
  };

  useEffect(() => {
    systemStateHandler(async (systemState: { id: number; state: SystemState }) => {
      dispatch({ type: AppActionTypes.SystemUpdate, payload: systemState });
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const accessToken = localStorage.getItem('bs-token');
        if (accessToken) {
          await initApp();
        } else {
          await logout();
        }
      } catch (err) {
        console.error(err);
        await logout();
      }
    };

    init();
  }, []);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        login,
        logout,
        resetPassword,
        updateProfile,
        setBarcode,
        setContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
