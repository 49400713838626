import { useEffect, useState } from 'react';
import Profile from 'components/profile';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const ProfileView = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return <Profile />;
};
