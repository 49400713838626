import { FormControl, FormLabel, Button, TextField, Divider, List, ListItem, ListItemText, ListItemIcon, Typography, Box, ButtonGroup, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import { InspectionQuestion, InspectionQuestionResult, InspectionResult } from 'entities';
import { Sensors } from '@mui/icons-material';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import useInspection from 'hooks/useInspection';
import { InspectionResultVM, SignalVM } from 'entities/viewModels';
import InputFileUploadField from 'components/fields/InputFileUploadField';
import { addOrUpdateArray } from 'utils/array';
import PerfectScrollbar from 'react-perfect-scrollbar';

type InspectionResultFormProps = {
  questions: InspectionQuestion[];
  signals?: SignalVM[];
  data?: InspectionResultVM;
  disabled?: boolean;
};

export const InspectionResultForm = ({ data, questions, signals, disabled }: InspectionResultFormProps) => {
  const [item, setItem] = useState(data!!);
  const { setDrawerData, handleInspectionResultSubmit } = useInspection();
  // const [outcome, setOutcome] = useState(editMode && data?.result ? (data?.type === 'Functional' ? data?.result?.outcomeFunctional : (data?.result.outcomeVisibility ?? 2)) : 2);
  // const [comment, setComment] = useState(editMode && data?.result ? (data?.type === 'Functional' ? data.result?.commentFunctional : (data?.result.commentVisibility ?? '')) : '');

  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Please submit a comment');

  const getResult = (id: number) => item?.results?.find((r) => r.inspectionQuestionID === id);

  const setAnswer = (questionId: number, outcome: InspectionOutcome, comment: string = '') => {
    if (disabled) return;
    const result: InspectionQuestionResult = getResult(questionId) ?? { id: 0, inspectionQuestionID: questionId };
    const update: InspectionResult = {
      ...item!!,
      results: addOrUpdateArray(item?.results ?? [], { ...result, outcome: outcome, comment: comment, date: new Date().toISOString() }, 'inspectionQuestionID'),
    };
    setItem(update);
    setDirty(true);
  };

  const RenderQuestion = (question: InspectionQuestion, result?: InspectionQuestionResult) => {
    const handlOutcomeChange = (questionId: number, outcome: InspectionOutcome) => setAnswer(questionId, outcome);
    const handleCommentChange = (questionId: number, comment: string) => setAnswer(questionId, InspectionOutcome.FAIL, comment);

    const sx: SxProps<Theme> = disabled ? { ':hover': { cursor: 'default', backgroundColor: 'transparent' } } : {};

    return (
      <Box key={question.id}>
        <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} key={question.id}>
          <FormLabel id={'outcome-label'}>{question?.name}</FormLabel>
          <ButtonGroup size='small' aria-label='Small button group' sx={{ height: 20 }}>
            <Button
              sx={sx}
              key='yes'
              color={result?.outcome === InspectionOutcome.PASS ? 'success' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.PASS)}
            >
              Yes
            </Button>
            <Button
              sx={sx}
              key='no'
              color={result?.outcome === InspectionOutcome.FAIL ? 'error' : undefined}
              onClick={() => handlOutcomeChange(question.id, InspectionOutcome.FAIL)}
            >
              No
            </Button>
          </ButtonGroup>
        </Box>
        {result?.outcome === InspectionOutcome.FAIL && (
          <TextField
            multiline
            rows={3}
            fullWidth
            error={error}
            autoComplete='off'
            label='Comment'
            variant='outlined'
            margin={'normal'}
            value={result?.comment}
            disabled={disabled}
            required={result?.outcome === InspectionOutcome.FAIL}
            onChange={(e) => handleCommentChange(question.id, e.target.value)}
          />
        )}
        {/* <FormHelperText>{helperText}</FormHelperText> */}
      </Box>
    );
  };

  const handleAllPassed = () => {
    questions.forEach((q) => {
      setAnswer(q.id, InspectionOutcome.PASS);
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleInspectionResultSubmit(item);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PerfectScrollbar
        style={{
          overflowX: 'hidden',
          height: '80vh',
        }}
      >
        <FormControl sx={{ m: 1, gap: '20px', width: '90%' }} variant='standard'>
          <Box gap={1} display={'flex'} flexDirection={'column'}>
            <Typography sx={{ fontWeight: 700 }}>{item?.device?.type?.name}</Typography>
            <Typography sx={{ fontSize: 'small' }}>{item?.device?.name}</Typography>
            <Typography sx={{ fontSize: 'small' }}>Address: {item?.device?.address}</Typography>
            {item?.device?.barCode && <Typography sx={{ fontSize: 'small' }}>Barcode: {item?.device?.barCode}</Typography>}
          </Box>
          <Divider />
          {/* <FormLabel id={'outcome-label'}>Outcome</FormLabel> */}
          {[...questions.filter((q) => q.deviceTypeID === null), ...questions.filter((q) => q.deviceTypeID === item?.device?.typeID)].map((q) =>
            RenderQuestion(q, getResult(q.id))
          )}
          <InputFileUploadField />
          <Box display='flex' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FormLabel id={'outcome-label'}>Does this pass all questions above?</FormLabel>
            <Button disabled={disabled} size='small' variant='outlined' key='yes' onClick={handleAllPassed}>
              Yes
            </Button>
          </Box>
          <Button disabled={!dirty} sx={{ mt: 1, mr: 1 }} type='submit' variant='contained'>
            Submit
          </Button>
          {signals && (
            <>
              <Divider />
              <FormLabel sx={{ fontWeight: 700 }}>Signal(s) raised</FormLabel>
              <List dense={true}>
                {signals?.map((s) => (
                  <ListItem key={s.id}>
                    <ListItemIcon>
                      <Sensors />
                    </ListItemIcon>
                    <ListItemText primary={`${s.type?.name} ${s.address ? `[${s.address}]` : ''}`} secondary={new Date(s.date).toLocaleString()} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </FormControl>
      </PerfectScrollbar>
    </form>
  );
};
